import {
  CREATE_NAVIGATION,
  FIND_NAVIGATION, GET_NAVIGATIONS_LIST,
  SET_NAVIGATION_ERROR, SWITCH_NAVIGATION_STATE,
  UPDATE_NAVIGATION
} from "../store/types";
import handleReject from "../common/handleReject";
import ApiService from "../common/api.service";

export default {
  methods: {
    deleteNavigation(id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete(`/manager/navigations/${id}`)
        .then(response=>{
          this.hideBusy();
          resolve(response);
        })
        .catch(response=>{
          this.hideBusy();
          handleReject(this.$store, response, SET_NAVIGATION_ERROR);
          reject(response);
        })
      })
    },
    createNavigation(formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(CREATE_NAVIGATION, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    updateNavigation(formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(UPDATE_NAVIGATION, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    findNavigation(id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(FIND_NAVIGATION, id)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    getNavigations(options = {page: 1, title: ''}, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_NAVIGATIONS_LIST, options)
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    switchNavigationState(id, params){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(SWITCH_NAVIGATION_STATE, {id, params})
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    }
  }
}
