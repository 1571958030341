<template>
  <div class="adverts-list">
    <error :errors="navigationError" />
    <b-overlay :show="formBusy" rounded="sm">
      <portlet fluidHeight>
        <template v-slot:body>
          <div class="d-flex align-items-center justify-content-between">
            <b-pagination
              class="mb-0 mr-2"
              v-model="navigationsMeta.current_page"
              :total-rows="navigationsMeta.total"
              :per-page="navigationsMeta.per_page"
              aria-controls="navigation"
              @change="onChangePage"
            />
            <div class="kt-searchbar d-none d-sm-flex w-25">
              <div class="kt-input-icon kt-input-icon--left">
                <b-input @blur="refreshList" v-model="title" type="search" class="form-control" placeholder="تىزىملىك نامىنى كىرگۈزۈپ ئىزدەڭ" />
                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                    <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                          <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                        </g>
                      </svg></span>
                  </span>
              </div>
            </div>
            <div class="actions">
              <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm btn-icon btn-icon-md">
                <i class="flaticon2-refresh-arrow"></i>
              </button>
              <b-button :to="{name: 'navigations.create'}" class="btn btn-label-success btn-sm border-0">
                يېڭىدىن قوشۇش
              </b-button>
            </div>
          </div>
        </template>
      </portlet>
      <b-row>
        <b-col v-for="navigation in navigations" md="3" :key="navigation.id" sm="6">
          <portlet title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:toolbar>
              <b-form-checkbox v-b-tooltip title="ھالەت تەڭشىكى" :key="`${navigation.id}_show`" switch @change="onSwitchShow(navigation.id)" v-model="navigation.is_show"/>
            </template>
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__media" :style="{backgroundColor: navigation.background}">
                    <b-img class="kt-widget__img" :class="{'kt-hidden': ! navigation.preview}" rounded="" height="50px" :src="navigation.preview" alt="image" />
                    <div :class="{'kt-hidden': navigation.preview}" class="kt-widget__pic kt-widget__pic--success kt-font-success kt-font-boldest">
                      {{ navigation.title.substring(0, 2) }}
                    </div>
                  </div>
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ navigation.title }}
                    </a>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__section">
                    <p class="rounded" :style="{backgroundColor: navigation.background, height: '20px', marginBottom: 0}"></p>
                  </div>
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">تىپى: </span>
                      <span class="kt-widget__data" v-if="navigation.target === 'category'">تۈرگە ئاتلايدۇ</span>
                      <span class="kt-widget__data" v-if="navigation.target === 'author'">يازغۇچىغا ئاتلايدۇ</span>
                      <span class="kt-widget__data" v-if="navigation.target === 'article'">ئەسەرگە ئاتلايدۇ</span>
                      <span class="kt-widget__data" v-if="navigation.target === 'mp'">ئەپچاققا ئاتلايدۇ</span>
                      <span class="kt-widget__data" v-if="navigation.target === 'external_link'">سىرتقى ئۇلانمىغا ئاتلايدۇ</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label" v-if="navigation.target === 'category'">تۈر نامى: </span>
                      <span class="kt-widget__label" v-if="navigation.target === 'author'">ئاپتور ئىسمى: </span>
                      <span class="kt-widget__label" v-if="navigation.target === 'article'">ئەسەر نامى: </span>
                      <span class="kt-widget__label" v-if="navigation.target === 'mp'">ئەپچاق ئادېرسى: </span>
                      <span class="kt-widget__label" v-if="navigation.target === 'external_link'">ئۇلانما ئادېرسى: </span>
                      <span class="kt-widget__data" v-if="navigation.target === 'category'">{{ navigation.navigationable ? navigation.navigationable.title : 'ئۆچۈرۈلگەن تۈر بولىشى مۇمكىن' }}</span>
                      <span class="kt-widget__data" v-if="navigation.target === 'author'">{{ navigation.navigationable ? navigation.navigationable.name : 'ئۆچۈرۈلگەن ئاپتور بولىشى مۇمكىن' }}</span>
                      <span class="kt-widget__data" v-if="navigation.target === 'article'">{{ navigation.navigationable ? navigation.navigationable.title : 'ئۆچۈرۈلگەن يازما بولىشى مۇمكىن' }}</span>
                      <span class="kt-widget__data" v-if="navigation.target === 'mp'">{{ navigation.to }}</span>
                      <span class="kt-widget__data" v-if="navigation.target === 'external_link'">
                        <b-button :href="navigation.to" target="_blank" variant="primary">بېسىپ كۆرۈڭ</b-button>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="kt-widget__footer">
                  <b-button :to="{name: 'navigations.edit', params: {id: navigation.id}}" type="button" variant="primary" class="btn btn-lg btn-upper">تەھرىرلەش</b-button>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <portlet v-if="navigations.length < 1" class="kt-callout">
        <template v-slot:body>
          <div class="kt-callout__body">
            <div class="kt-callout__content">
              <h3 class="kt-callout__title">ئۇچۇر تېپىلمىدى</h3>
              <p class="kt-callout__desc">
                مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن ئۇچۇر قوشۇڭ
              </p>
            </div>
            <div class="kt-callout__action">
              <b-button :to="{name: 'navigations.create'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-success">يېڭىدىن قوشۇش</b-button>
            </div>
          </div>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import Error from "../../../partials/widgets/Error";
  import { mapGetters } from "vuex";
  import navigationMixin from "../../../../mixins/navigationMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import Portlet from "../../../partials/content/Portlet";
  export default {
    name: "index",
    components: { Portlet, Error },
    mixins: [ navigationMixin, formBusyMixin ],
    computed: {
      ...mapGetters(["navigationError", "navigations", "navigationsMeta"])
    },
    created() {
      this.getNavigations();
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "باشلاش تىزىملىكى باشقۇرۇش", route: "list" },
        { title: "بارلىق تىزىملىك" }
      ]);
    },
    data(){
      return {
        tableFields: [
          { key: "id", label: "id", class: ['d-none', 'd-md-table-cell'] },
          { key: "preview", label: "سىنبەلگە" },
          { key: "title", label: "تىزىملىك نامى" },
          { key: "switchShow", label: "ھالىتى" },
          { key: "actions", label: "مەشغۇلات" }
        ],
        tableBusy: false,
        title: '',
        formBusy: false,
      };
    },
    methods: {
      onChangePage(page){
        this.getNavigations({page});
      },
      deleteItem(id) {
        this.$bvModal.msgBoxConfirm('مەزكۇر باشلاش تىزىملىكىنى ئ‍ۆچۈرسىڭىز ئەسلىگە قايتۇرغىلى بولمايدۇ، ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟', {
          title: 'راستىنلا ئۆچۈرەمسىز ؟',
          okVariant: 'danger',
          okTitle: 'جەزىملەشتۈرىمەن',
          cancelTitle: 'ياق',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if ( ! value ) return;
          this.deleteNavigation(id)
                  .then(()=>{
                    let page = this.page;
                    this.getNavigations({page});
                  })
        })
        .catch(err => {
          // An error occurred
        })
      },
      refreshList(){
        let {title} = this;
        this.getNavigations({ page:1, title });
      },
      searchNavigation(){
        let title = this.title;
        this.getNavigations({page: 1, title});
      },
      onSwitchShow(e){
        this.switchNavigationState(e, {column: 'is_show'});
      },
    }
  }
</script>

<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__section {
    overflow: hidden;
    height: auto;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }
</style>
